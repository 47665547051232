<template>
  <div class="popup_wrap doc_req_pop" style="width:900px; height:600px;">
    <button class="layer_close" @click="$emit('close')">close</button>
    <form
      id="frm_req_pop"
      @submit.prevent
    >
      <div class="popup_cont cod_cont_area">
        <h1 class="page_title">
          <!-- Shipback 요청서 -->
          {{ $t('msg.ONEX010P080.001') }}
        </h1>
        <div class="content_box">
          <!-- content_box -->
          <p v-if="memberDetail.userCtrCd !== 'KR'">
            {{ $t('msg.ONEX010P080.002') }}
          </p>
          <p class="mt5">
            <span v-show="memberDetail.userCtrCd !== 'KR'"><a class="button sm" href="#" @click.prevent="excelDownload('ShipbackRequest.xlsx')">{{ $t('msg.ONEX010P080.003') }}</a></span><!-- Shipback 요청서 양식 다운 -->
            <span v-if="!(memberDetail.userCtrCd === 'KR' || memberDetail.userCtrCd === 'JP' || memberDetail.userCtrCd === 'HK' || memberDetail.userCtrCd === 'ID' || memberDetail.userCtrCd === 'MY' && $store.getters['auth/getCalcEiCatCd'] === 'O')" class="ml5">
              <a class="button sm" href="#" @click.prevent="moveDelegateReqPage()">{{ $t('msg.ONEX010P080.004') }}</a></span><!-- 위수임 신청 바로가기 -->
            <span v-if="!(memberDetail.userCtrCd === 'KR' || memberDetail.userCtrCd === 'JP' || memberDetail.userCtrCd === 'HK' || memberDetail.userCtrCd === 'ID' || memberDetail.userCtrCd === 'MY' && $store.getters['auth/getCalcEiCatCd'] === 'O')">
              <button type="button" class="tbl_icon help ml10" @click.prevent="guidePop()">{{ $t('msg.ONEX010P080.005') }}</button><!-- 도움말 -->
            </span>
          </p>
          <h2 class="content_title">{{ $t('msg.ONEX010P080.006') }}</h2><!-- Shipback 요청서 제출 -->
          <table class="tbl_row">
            <colgroup>
              <col style="width:200px">
              <col>
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">{{ $t('msg.ONEX010P080.007') }}</th><!-- 제출 -->
                <td>
                  <div v-if="memberDetail.userCtrCd == 'KR'" class="filebox" id="file_att">
                    <a
                      class="button sm gray file"
                      @click.prevent="openEleConsReqDocPop()"
                    >
                      {{ $t('js.booking.043') }} <!-- 양식작성 -->
                    </a>
                  </div>
                  <div v-else class="filebox" id="file_att">
                    <a
                      v-if="uploadParam.length === 0"
                      class="button sm gray file"
                      @click.prevent="uploadFilePop()"
                    >
                      {{ $t('msg.ONEX010P080.008') }}
                    </a>
                    <div v-if="uploadParam.length > 0">
                      <span class="tbl_icon attach"></span>
                      <a class="text_link">{{ uploadParam[0].originalName }}</a>
                      <a
                        class="tbl_icon attdel"
                        @click="removeUploadFile()"
                      >X</a>
                    </div>
                  </div>
                </td>
                <td class="border_left_none">
                </td>
              </tr>
            </tbody>
          </table>
          <p class="txt_desc mt10" style="background: none;">
            <span v-if="userList.length > 0 && userList[0] !== null && userList[0] !== undefined">
              <span v-for="(ulist, idx) in userList" :key="idx">
                <span class="font_medium">[{{ $t('msg.ONEX010P080.013') }}]</span> TEL : {{ ulist.usrPhone }}
                <span class="txt_bar"></span> {{ $t('msg.ONEX010P080.015') }} : {{ ulist.usrEmail }} <br>
              </span>
            </span>
          </p>
        </div><!-- content_box // -->

        <div class="mt10 text_right">
          <a v-show="memberDetail.userCtrCd !== 'KR'" class="button blue lg mr5" @click="reqSave()">{{ $t('msg.ONEX010P080.017') }}</a>
          <a class="button gray lg" @click="confirmClose()">{{ $t('msg.ONEX010P080.012') }}</a>
        </div>

        <win-layer-pop v-if="memberDetail.userCtrCd == 'KR'" ref="openPop" class="popup_dim_snd">
          <component
            :is="popComponent"
            v-if="popComponent"
            :parent-info="popParams"
            @close="closeCommonPopup"
            @callback="popCallback"
          />
        </win-layer-pop>

        <win-layer-pop v-else ref="uploadPop" class="popup_dim">
          <component
            :is="customComponent"
            v-if="customComponent"
            :parent-info="uploadParam"
            :single-file-yn="true"
            @set-dext-file-info="arrangeUploadParam"
            @close="closePopup"
          />
        </win-layer-pop>

        <div v-show="false" id="guide_pop">
          <component
            :is="customComponent"
            v-if="customComponent"
            @close="closePopup"
          />
        </div>
      </div><!-- popup_cont -->
    </form>
  </div>
</template>

<script>

import commons from '@/api/services/commons'
import docs from '@/api/rest/trans/docs'
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
import ContainerGuidePop from '@/pages/trans/popup/ContainerGuidePop'
import DocUploadPop from '@/pages/trans/popup/DocUploadPop'
import { rootComputed } from '@/store/helpers'
import myRequest from '@/api/rest/trans/myRequest'
import EleConsReqDocPop from '@/pages/trans/popup/eleConsReqDocPop'
import CodShipbackReqPop from '@/pages/trans/popup/CodShipbackReqPop'

export default {
  name: 'ShipbackReqPop',
  components: {
    rootComputed,
    WinLayerPop,
    DocUploadPop,
    ContainerGuidePop,
    EleConsReqDocPop,
    CodShipbackReqPop
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          blNo: ''
        }
      }
    }
  },
  data () {
    return {
      ops: {
        scrollPanel: {
          speed: 300
        },
        bar: {
          showDelay: 500,
          keepShow: true,
          background: '#c1c1c1',
          opacity: 1
        }
      },
      nowDate: '',
      bookingInfo: {
        type: Object,
        default: null
      },
      cntrTypeList: [
        { cdNm: 'Dry', cd: 'GP', rmk: '20/40' },
        { cdNm: 'HIGH CUBE', cd: 'HC', rmk: '40/45' },
        { cdNm: 'REEFER', cd: 'RF', rmk: '20' },
        { cdNm: 'REEFER HIGH CUBE', cd: 'RH', rmk: '40' },
        { cdNm: 'TANK', cd: 'TK', rmk: '20/40' },
        { cdNm: 'OPEN TOP', cd: 'OT', rmk: '20/40' },
        { cdNm: 'FLAT RACK', cd: 'FR', rmk: '20/40' },
        { cdNm: 'SUPER RACK', cd: 'SR', rmk: '40' }
      ],
      agreeYn: 'N',
      picNmh: '',
      toolTipActive: false,
      cstInfoList: [],
      cntrList: [],
      selectFunc: null,
      customComponent: null,
      popParams: {},
      uploadParam: [],
      formData: {
        entPicNo: '',
        reqPicNm: '',
        reqPicTelNo: '',
        reqPicEmlAddr: '',
        reqRsnCont: '',
        // 현재 화면 캡쳐해서 보내는 로직 - 실제로 컨테이너의 정보 데이터는 저장하지 않음
        containerList: [],
        uploadFileInfos: []
      },
      userList: [],
      popComponent: null
    }
  },
  computed: {
    ...rootComputed
  },
  async created () {
    const parentInfo = this.parentInfo
    const TH = this
    let msg

    if (this.$ekmtcCommon.isNotEmpty(parentInfo.blNo)) {
      const params = {
        blNo: parentInfo.blNo,
        reqCatCd: '14'
      }

      await docs.getCntReqMas(params)
        .then(res => {
          // console.log('getCntReqMas @@@@ ', res)
          msg = res.headers.respmsg
        })

      if (msg === 'SUCC') {
        this.init()
      } else {
        this.$ekmtcCommon.alertCallback(this.$t('art.CSBL220.016'), function () {
          TH.$emit('close')
        })
      }
    } else {
      const TH = this
      this.$ekmtcCommon.alertCallback(this.$t('msg.ONEX070G050.014'), function () {
        TH.$emit('close')
      })
    }
  },
  methods: {
    async init () {
      const TH = this
      const parentInfo = TH.parentInfo

      await this.userIngListFnc()

      // 로그인한 정보를 이용하여 프로필 정보 가져오기
      if (this.auth !== undefined) {
        const data = this.selectedProfile
        this.formData.entPicNo = data.picNo
        this.formData.reqPicNm = data.picNm
        this.formData.reqPicEmlAddr = data.emlAddr

        let usrTelNo = data.telPlcNo || ''

        if (this.$ekmtcCommon.isNotEmpty(data.telOfcNo)) {
          usrTelNo += '-' + data.telOfcNo
        }

        if (this.$ekmtcCommon.isNotEmpty(data.telNo)) {
          usrTelNo += '-' + data.telNo
        }

        this.formData.reqPicTelNo = usrTelNo
      } else {
        await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.REPORT_PDF.115') }) // 로그인을 확인해주세요.
        this.$emit('close')
        return
      }

      await Promise.all([
        commons.getServerTime().then(res => {
          if (res.data !== undefined && res.data !== null) {
            TH.nowDate = res.data.substring(0, 8)
          }
        }),
        commons.getCstInfo().then(res => {
          this.cstInfoList = res
        }),
        docs.getReqDocBookingBl(parentInfo.blNo).then(async res => {
          const result = res.data
          this.bookingInfo = result
        })
      ])
    },
    async reqSave () {
      let isOk = true
      const TH = this
      const formData = TH.formData

      formData.uploadFileInfos = this.uploadParam.map((item) => {
        if (item.new !== true) {
          item.fileId = item.asFileId
        }
        return item
      })

      TH.$delete(formData, 'containerList')

      if (formData.uploadFileInfos.length === 0) {
        const attachSelector = document.querySelector('#file_att')
        TH.$ekmtcCommon.showErrorTooltip(attachSelector, TH.$t('msg.CSBK100.147'))
        isOk = false
      }

      if (isOk) {
        let successFlag = true

        formData.vslCd = TH.bookingInfo.vslCd
        formData.voyNo = TH.bookingInfo.voyNo
        formData.polCtrCd = TH.bookingInfo.polCtrCd
        formData.polPortCd = TH.bookingInfo.polPortCd
        formData.podCtrCd = TH.bookingInfo.podCtrCd
        formData.podPortCd = TH.bookingInfo.podPortCd
        formData.shprCstNo = TH.bookingInfo.shprCstNo
        formData.shprCstNm = TH.bookingInfo.shprCstNm
        formData.blNo = TH.parentInfo.blNo
        formData.bkgNo = TH.bookingInfo.bkgNo
        formData.reqCatCd = '14'

        await docs.reqDocSubmit(formData).then(res => {
          if (res.headers.respcode !== 'C0000') {
            successFlag = false
          }
        }).catch(() => {
          successFlag = false
        }).finally(() => {
          if (successFlag) {
            TH.$ekmtcCommon.alertCallback(TH.$t('msg.CSBK100.236'), function () {
              TH.$emit('selectFunc')
              TH.$emit('close')
            })
          } else {
            TH.$ekmtcCommon.alertDefault(TH.$t('msg.CSBK100.237'))
          }
        })
      }
    },
    uploadFilePop () {
      const uploadParam = this.uploadParam

      this.$set(this, 'uploadParam', uploadParam)
      this.openPopup('DocUploadPop')
    },
    guidePop () {
      this.openPopup('ContainerGuidePop')
    },
    openPopup (compNm) {
      this.customComponent = compNm
      this.$ekmtcCommon.layerOpen('.doc_req_pop .popup_dim')
    },
    closePopup (compNm) {
      const elemGuidPopup = document.querySelector('#guide_pop')
      this.customComponent = null
      this.selectFunc = null

      if (compNm !== 'confirmClose') {
        elemGuidPopup.style.display = 'none'
      } else {
        this.$emit('close')
      }

      this.$ekmtcCommon.layerClose('.doc_req_pop .popup_dim')
    },
    arrangeUploadParam () {
      const attachSelector = document.querySelector('#file_att')
      this.$ekmtcCommon.hideErrorTooltip(attachSelector)
      this.uploadParam.map((item) => (item.newFlag = item.new))
    },
    removeUploadFile () {
      this.uploadParam.splice(0, 1)
      this.customComponent = null
    },
    async moveDelegateReqPage () {
      const TH = this
      // 위수임 신청 페이지로 이동하시겠습니까?
      if (await TH.$ekmtcCommon.asyncAlertMessage({ message: TH.$t('msg.ONEX060P012.089'), useConfirmBtn: true })) {
        TH.$emit('close')
        TH.$router.push({ name: 'import-business-agency-information-main' })
      }
    },
    async confirmClose () {
      //if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONEX020P130.062'), useConfirmBtn: true })) {
        this.$emit('close')
      //}
    },
    async excelDownload (fileName) {
      console.log('#### excel Download ####')

      const data = {
        fileNm: fileName,
        blNo: this.parentInfo.blNo
      }

      if (this.$ekmtcCommon.isNotEmpty(this.parentInfo.blNo)) {
        //using blob download
        const result = await docs.getExcelDownload(data)
        console.log('##### excelDownload #####')
        console.log(result)
        //blob 객체 생성
        const blob = new Blob([result.data], { type: result.headers['content-type'] })

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          //IE에서 동작
          window.navigator.msSaveOrOpenBlob(result.data, fileName)
        } else {
          console.log(result.data)
          //<a>태그에 URL화 시킨 blob객체를 연결시킨 후 다운로드
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')

          link.href = url
          link.target = '_self'
          link.download = fileName
          link.click()

          //url 사용 후 메모리에서 제거
          window.URL.revokeObjectURL(url)
        }
      }
    },
    async userIngListFnc () {
      let blNo = this.parentInfo.blNo

      const THIS = this
      await myRequest.getUserIngInfo({ blNo: blNo, ntcCd: '04' }).then(res => {
        const vo = res.data
        if (vo) {
          THIS.userList = vo.myRequestUserList
        }
      })
    },
    openCommonPopup (compNm) {
      this.popComponent = compNm
      this.$ekmtcCommon.layerOpen('.doc_req_pop .popup_dim_snd')
    },
    closeCommonPopup (compNm) {
      this.popComponent = null

      this.$ekmtcCommon.layerClose('.doc_req_pop .popup_dim_snd')
    },
    openEleConsReqDocPop () {
      this.popParams.reqCatCd = '14'
      this.popParams.bkgNo = this.parentInfo.bkgNo
      this.popParams.blNo = this.parentInfo.blNo

      this.openCommonPopup('EleConsReqDocPop')
    },
    popCallback (callParams) {
      console.log('popCallback @@@@@ ', callParams)

      this.openCommonPopup('CodShipbackReqPop')
    }
  }
}
</script>

<style scoped>
.doc_req_pop {width:910px; height: 600px; overflow-y: scroll;}
.doc_req_pop > .layer_close {z-index: 10;}
.div_cntr_area + .div_cntr_area {margin-top: 5px;}
.text_link {color: #000; padding-left: 2px;}
.text_link:hover {text-decoration: underline !important;}
.attdel {cursor: pointer;}
</style>
