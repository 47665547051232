var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap doc_req_pop",
      staticStyle: { width: "900px", height: "600px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c(
        "form",
        {
          attrs: { id: "frm_req_pop" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "popup_cont cod_cont_area" },
            [
              _c("h1", { staticClass: "page_title" }, [
                _vm._v(" " + _vm._s(_vm.$t("msg.ONEX010P080.001")) + " "),
              ]),
              _c("div", { staticClass: "content_box" }, [
                _vm.memberDetail.userCtrCd !== "KR"
                  ? _c("p", [
                      _vm._v(" " + _vm._s(_vm.$t("msg.ONEX010P080.002")) + " "),
                    ])
                  : _vm._e(),
                _c("p", { staticClass: "mt5" }, [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.memberDetail.userCtrCd !== "KR",
                          expression: "memberDetail.userCtrCd !== 'KR'",
                        },
                      ],
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "button sm",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.excelDownload("ShipbackRequest.xlsx")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("msg.ONEX010P080.003")))]
                      ),
                    ]
                  ),
                  !(
                    _vm.memberDetail.userCtrCd === "KR" ||
                    _vm.memberDetail.userCtrCd === "JP" ||
                    _vm.memberDetail.userCtrCd === "HK" ||
                    _vm.memberDetail.userCtrCd === "ID" ||
                    (_vm.memberDetail.userCtrCd === "MY" &&
                      _vm.$store.getters["auth/getCalcEiCatCd"] === "O")
                  )
                    ? _c("span", { staticClass: "ml5" }, [
                        _c(
                          "a",
                          {
                            staticClass: "button sm",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.moveDelegateReqPage()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("msg.ONEX010P080.004")))]
                        ),
                      ])
                    : _vm._e(),
                  !(
                    _vm.memberDetail.userCtrCd === "KR" ||
                    _vm.memberDetail.userCtrCd === "JP" ||
                    _vm.memberDetail.userCtrCd === "HK" ||
                    _vm.memberDetail.userCtrCd === "ID" ||
                    (_vm.memberDetail.userCtrCd === "MY" &&
                      _vm.$store.getters["auth/getCalcEiCatCd"] === "O")
                  )
                    ? _c("span", [
                        _c(
                          "button",
                          {
                            staticClass: "tbl_icon help ml10",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.guidePop()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("msg.ONEX010P080.005")))]
                        ),
                      ])
                    : _vm._e(),
                ]),
                _c("h2", { staticClass: "content_title" }, [
                  _vm._v(_vm._s(_vm.$t("msg.ONEX010P080.006"))),
                ]),
                _c("table", { staticClass: "tbl_row" }, [
                  _vm._m(0),
                  _c("tbody", [
                    _c("tr", [
                      _c("th", { attrs: { scope: "row" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.ONEX010P080.007"))),
                      ]),
                      _c("td", [
                        _vm.memberDetail.userCtrCd == "KR"
                          ? _c(
                              "div",
                              {
                                staticClass: "filebox",
                                attrs: { id: "file_att" },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "button sm gray file",
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.openEleConsReqDocPop()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("js.booking.043")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _c(
                              "div",
                              {
                                staticClass: "filebox",
                                attrs: { id: "file_att" },
                              },
                              [
                                _vm.uploadParam.length === 0
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "button sm gray file",
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.uploadFilePop()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("msg.ONEX010P080.008")
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.uploadParam.length > 0
                                  ? _c("div", [
                                      _c("span", {
                                        staticClass: "tbl_icon attach",
                                      }),
                                      _c("a", { staticClass: "text_link" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.uploadParam[0].originalName
                                          )
                                        ),
                                      ]),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "tbl_icon attdel",
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeUploadFile()
                                            },
                                          },
                                        },
                                        [_vm._v("X")]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                      ]),
                      _c("td", { staticClass: "border_left_none" }),
                    ]),
                  ]),
                ]),
                _c(
                  "p",
                  {
                    staticClass: "txt_desc mt10",
                    staticStyle: { background: "none" },
                  },
                  [
                    _vm.userList.length > 0 &&
                    _vm.userList[0] !== null &&
                    _vm.userList[0] !== undefined
                      ? _c(
                          "span",
                          _vm._l(_vm.userList, function (ulist, idx) {
                            return _c("span", { key: idx }, [
                              _c("span", { staticClass: "font_medium" }, [
                                _vm._v(
                                  "[" +
                                    _vm._s(_vm.$t("msg.ONEX010P080.013")) +
                                    "]"
                                ),
                              ]),
                              _vm._v(" TEL : " + _vm._s(ulist.usrPhone) + " "),
                              _c("span", { staticClass: "txt_bar" }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("msg.ONEX010P080.015")) +
                                  " : " +
                                  _vm._s(ulist.usrEmail) +
                                  " "
                              ),
                              _c("br"),
                            ])
                          }),
                          0
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
              _c("div", { staticClass: "mt10 text_right" }, [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.memberDetail.userCtrCd !== "KR",
                        expression: "memberDetail.userCtrCd !== 'KR'",
                      },
                    ],
                    staticClass: "button blue lg mr5",
                    on: {
                      click: function ($event) {
                        return _vm.reqSave()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.ONEX010P080.017")))]
                ),
                _c(
                  "a",
                  {
                    staticClass: "button gray lg",
                    on: {
                      click: function ($event) {
                        return _vm.confirmClose()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.ONEX010P080.012")))]
                ),
              ]),
              _vm.memberDetail.userCtrCd == "KR"
                ? _c(
                    "win-layer-pop",
                    { ref: "openPop", staticClass: "popup_dim_snd" },
                    [
                      _vm.popComponent
                        ? _c(_vm.popComponent, {
                            tag: "component",
                            attrs: { "parent-info": _vm.popParams },
                            on: {
                              close: _vm.closeCommonPopup,
                              callback: _vm.popCallback,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _c(
                    "win-layer-pop",
                    { ref: "uploadPop", staticClass: "popup_dim" },
                    [
                      _vm.customComponent
                        ? _c(_vm.customComponent, {
                            tag: "component",
                            attrs: {
                              "parent-info": _vm.uploadParam,
                              "single-file-yn": true,
                            },
                            on: {
                              "set-dext-file-info": _vm.arrangeUploadParam,
                              close: _vm.closePopup,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: false,
                      expression: "false",
                    },
                  ],
                  attrs: { id: "guide_pop" },
                },
                [
                  _vm.customComponent
                    ? _c(_vm.customComponent, {
                        tag: "component",
                        on: { close: _vm.closePopup },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "200px" } }),
      _c("col"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }